import React, { useEffect, useRef, useState } from "react";

const Skills = () => {
  const [isVisible, setIsVisible] = useState(false);
  const skillsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Apply animation only for non-mobile views (screens wider than 768px)
        if (window.innerWidth > 768) {
          setIsVisible(entry.isIntersecting);
        } else {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (skillsRef.current) {
      observer.observe(skillsRef.current);
    }

    return () => {
      if (skillsRef.current) {
        observer.unobserve(skillsRef.current);
      }
    };
  }, []);

  return (
    <section
      id="skills"
      ref={skillsRef}
      className="py-16 bg-white relative md:h-screen overflow-hidden"
    >
      {/* The h-screen class will only apply for screens larger than 768px */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-gray-900 to-gray-200 z-10"></div>
      <div className="container mx-auto px-4 relative z-20">
        <h2 className="text-3xl font-bold mb-4 text-gray-900">
          Technical Skills
        </h2>
        <hr className="mb-8" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Programming Languages */}
          <div
            className="p-6 bg-white rounded-lg shadow-md"
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
          >
            <div
              className="p-8 rounded-lg shadow-lg bg-white relative"
              style={{ borderRadius: "inherit" }}
            >
              <h3
                className="text-xl font-semibold mb-2 text-gray-900"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "2%",
                  marginBottom: "10%",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  fontFamily: "'Poppins', sans-serif",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  borderRadius: "8px",
                  border: "2px solid #ffffff",
                }}
              >
                Programming Languages
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <img src="/images/html.png" alt="HTML" className="w-24 h-24" />
                <img src="/images/css.png" alt="CSS" className="w-24 h-24" />
                <img
                  src="/images/javascript.png"
                  alt="JavaScript"
                  className="w-24 h-24"
                />
                <img src="/images/php.png" alt="PHP" className="w-24 h-24" />
                <img
                  src="/images/dotnet.svg"
                  alt="ASP.net"
                  className="w-24 h-24"
                />
                <img src="/images/java.png" alt="Java" className="w-24 h-24" />
                <img
                  src="/images/csharp.svg"
                  alt="csharp"
                  className="w-24 h-24"
                />
                <img
                  src="/images/kotlin.png"
                  alt="kotlin"
                  className="w-24 h-24"
                />
                <img src="/images/dart.png" alt="Dart" className="w-24 h-24" />
              </div>
            </div>
          </div>

          {/* Frameworks & Libraries */}
          <div
            className="p-6 bg-white rounded-lg shadow-md"
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
          >
            <div
              className="p-8 rounded-lg shadow-lg bg-white relative"
              style={{ borderRadius: "inherit" }}
            >
              <h3
                className="text-xl font-semibold mb-2 text-gray-900"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "2%",
                  marginBottom: "10%",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  fontFamily: "'Poppins', sans-serif",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  borderRadius: "8px",
                  border: "2px solid #ffffff",
                }}
              >
                Frameworks & Libraries
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <img
                  src="/images/react.png"
                  alt="React.js"
                  className="w-24 h-24"
                />
                <img
                  src="/images/nodejs.png"
                  alt="Node.js"
                  className="w-24 h-24"
                />
                <img src="/images/d3.png" alt="D3" className="w-24 h-24" />
                <img src="/images/ajax.png" alt="AJAX" className="w-24 h-24" />
                <img src="/images/json.png" alt="JSON" className="w-24 h-24" />
                <img
                  src="/images/jquery.png"
                  alt="jQuery"
                  className="w-24 h-24"
                />
                <img
                  src="/images/bootstrap.png"
                  alt="BOOTSTRAP"
                  className="w-24 h-24"
                />
                <img
                  src="/images/tailwind.png"
                  alt="tailwind css"
                  className="w-24 h-24"
                />
                <img
                  src="/images/netcore.png"
                  alt="ASP .net Core"
                  className="w-24 h-24"
                />
              </div>
            </div>
          </div>

          {/* Other Skills */}
          <div
            className="p-6 bg-white rounded-lg shadow-md"
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
          >
            <div
              className="p-8 rounded-lg shadow-lg bg-white relative"
              style={{ borderRadius: "inherit" }}
            >
              <h3
                className="text-xl font-semibold mb-2"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "2%",
                  marginBottom: "10%",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  fontFamily: "'Poppins', sans-serif",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  borderRadius: "8px",
                  border: "2px solid #ffffff",
                }}
              >
                Other Skills
              </h3>

              <div className="grid grid-cols-2 gap-4">
                <img
                  src="/images/flutter.png"
                  alt="flutter"
                  className="w-24 h-24"
                />
                <img
                  src="/images/mysql.png"
                  alt="MySQL Server"
                  className="w-24 h-24"
                />
                <img
                  src="/images/docker.png"
                  alt="docker"
                  className="w-24 h-24"
                />
                <img
                  src="/images/mongodb.png"
                  alt="mongo DB"
                  className="w-24 h-24"
                />
                <img src="/images/xd.png" alt="xd" className="w-24 h-24" />
                <img
                  src="/images/figma.png"
                  alt="figma"
                  className="w-24 h-24"
                />
                <img
                  src="/images/wordpress.png"
                  alt="WordPress"
                  className="w-24 h-24"
                />
                <img src="/images/jira.png" alt="JIRA" className="w-24 h-24" />
                <img
                  src="/images/android.png"
                  alt="Android Studio"
                  className="w-24 h-24"
                />
                <img src="/images/git.png" alt="Git" className="w-24 h-24" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
