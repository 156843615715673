import React from "react";
import Header from "./components/Header";
import About from "./components/About";
import Skills from "./components/Skills";
import Education from "./components/Education";
import Jobs from "./components/Jobs";

import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Menu from "./components/Menu";

function App() {
  return (
    <div
      className="font-sans"
      style={{ background: "linear-gradient(270deg, black, transparent)" }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          zIndex: 2,
        }}
      >
        <Menu />
      </div>
      <main style={{ position: "relative", zIndex: 1 }}>
        <div
        // style={{
        //   background: "linear-gradient(184deg, black, transparent)",
        //   position: "fixed",
        //   zIndex: 0,
        //   width: "100%",
        //   height: "100vh",
        // }}
        />
        <Header />

        <About />
        <Skills />
        <Education />
        <Jobs />

        <Projects />
        <Contact />
      </main>
      <footer className="bg-gray-900 text-white text-center py-4">
        &copy; {new Date().getFullYear()} Abhishek Janga. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
