import React, { useState } from "react";

const Projects = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const handleDemoClick = (project) => {
    setCurrentProject(project);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setCurrentProject(null);
  };

  return (
    <section
      className="min-h-screen py-16 flex items-center justify-center relative overflow-hidden"
      id="projects"
    >
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center text-gray-900">
          Academic Projects
        </h2>
        <hr className="mb-8 mx-auto w-1/6 border-t-2 border-gray-400" />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {projectData.map((project, index) => (
            <div
              style={{
                background: "linear-gradient(45deg, black, white)",
                padding: "10px",
              }}
              key={index}
              className="project bg-white rounded-lg shadow-lg overflow-hidden transform transition-all hover:scale-105 duration-300 relative"
            >
              <div style={{ backgroundColor: "white" }}>
                <div className="relative">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-64 object-contain bg-white"
                    style={{
                      padding: "4%",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 1.2)",
                    }}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center transition duration-300 ease-in-out">
                    <ul className="text-white font-semibold text-lg">
                      <b>Technologies Used:</b>
                      <br />
                      {project.technologies.map((tech, i) => (
                        <li key={i}>{tech}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">
                    {project.title}
                  </h3>
                  <p className="text-base text-gray-700 mb-4">
                    {project.description}
                  </p>
                  <div className="flex justify-center gap-4 mt-4">
                    <a
                      href={project.link}
                      className="text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                      style={{ backgroundColor: "black" }}
                    >
                      View Source Code
                    </a>
                    <button
                      onClick={() => handleDemoClick(project)}
                      className="text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
                      style={{ border: "1px solid black", color: "black" }}
                    >
                      View Demo Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {popupVisible && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={closePopup}
          >
            <div
              className="bg-white p-8 rounded-lg shadow-lg relative"
              style={{ width: "90%", maxWidth: "500px" }}
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src="/images/recording.gif" // Replace with your image path
                alt="Under Construction"
                className="mx-auto mb-4"
                style={{ width: "150px", height: "150px" }}
              />
              <h2 className="text-2xl font-bold mb-4 text-center">
                {currentProject?.title} - Under Process
              </h2>
              <p className="text-center text-gray-700 mb-4">
                This demo video is currently under process.!
              </p>
              <button
                className="block mx-auto text-white px-4 py-2 rounded-lg bg-black hover:bg-gray-800"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const projectData = [
  {
    title: "HealthEase (Hospital Management System)",
    description:
      "An advanced system for managing hospital operations for all the users like admin, doctors and patients, including appointment scheduling, medical record access, and inventory control, aimed at improving patient and provider experiences.",
    image: "/images/healthease.png",
    link: "https://github.com/Janga-Abhishek/Group6_Capestone",
    demoLink: "https://example.com/demo/healthease",
    technologies: [
      "React",
      "Node.js",
      "Express",
      "Bootstrap",
      "Chart JS",
      "Email JS",
      "MongoDB",
    ],
  },
  {
    title: "Driving Test App",
    description:
      "A full-stack application for managing driving tests with separate roles for Admins, Examiners, and Drivers. Features include test booking, appointment management, and retake prompts based on test outcomes.",
    image: "/images/drivetest.png",
    link: "https://github.com/Janga-Abhishek/Drive_Test_App",
    demoLink: "https://example.com/demo/driving-test-app",
    technologies: ["Node.js", "EJS", "JavaScript", "Bootstrap"],
  },
  {
    title: "Janga Pet Store",
    description:
      "A comprehensive e-commerce platform built with ASP.NET Core. Features include admin capabilities for managing products and user functionalities for shopping and cart management and retrive all the previous orders.",
    image: "/images/petStore.png",
    link: "https://github.com/Janga-Abhishek/Janga_Pet_Store",
    demoLink: "https://example.com/demo/janga-pet-store",
    technologies: ["ASP.NET Core", "C#", "Entity Framework", "SQL Server"],
  },

  {
    title: "Whiskey Mobile Application",
    description:
      "An Android app integrated with Firebase for product management and user cart management. Includes dual login systems for Admins and Users, with session persistence and real-time data handling.",
    image: "/images/wiskey_shopping_app.png",
    link: "https://github.com/Janga-Abhishek/Whiskey_Shopping_mobileApp",
    demoLink: "https://example.com/demo/whiskey-mobile-app",
    technologies: ["Java", "Firebase", "XML", "Android Studio"],
  },
  {
    title: "AJ Connect",
    description:
      "A mobile app for professional networking, built with Dart and Firebase. It supports profile creation, connection management, and job postings, designed to enhance career opportunities and networking.",
    image: "/images/ajConnect.png",
    link: "https://github.com/Janga-Abhishek/AJ_Connect",
    demoLink: "https://example.com/demo/aj-connect",
    technologies: ["Dart", "Firebase", "Flutter"],
  },
  {
    title: "Online Pharmacy",
    description:
      "A web application for ordering medicines with prescription upload and status notifications. Includes interfaces for customers and pharmacy staff to manage orders and prescriptions.",
    image: "/images/onlinePharmacy.jpeg",
    link: "https://github.com/Janga-Abhishek/Online-Pharmacy",
    demoLink: "https://example.com/demo/online-pharmacy",
    technologies: ["PHP", "HTML", "CSS", "XAMPP server"],
  },
];

export default Projects;
