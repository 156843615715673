import React, { useEffect, useState, useRef } from "react";

const About = () => {
  const [isVisible, setIsVisible] = useState(true);
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    const currentRef = aboutRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      id="about"
      ref={aboutRef}
      className="min-h-screen py-16 flex items-center justify-center relative overflow-hidden"
    >
      <div className="absolute top-0 left-0 w-full h-full z-10"></div>

      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center relative z-20">
        <div
          className="relative p-1 rounded-lg"
          style={{
            background: "linear-gradient(45deg, black, white)",
            padding: "5px",
            marginBottom: "15%",
          }}
        >
          <div
            className="p-8 rounded-lg shadow-lg bg-white relative"
            style={{ borderRadius: "inherit" }}
          >
            <div>
              <h2 className="text-3xl font-bold mb-4 text-gray-900">
                About Me
              </h2>
              <hr className="mb-8" />
<ul className="list-disc pl-5 space-y-4 text-gray-700 text-lg leading-relaxed">
  <li>
    As an Experienced Web Developer, I bring a comprehensive skill set in PHP, JavaScript, Node.js, React, Java, and MongoDB, combined with a strong foundation in HTML5, CSS3, Responsive Web Design, SEO optimization, and WCAG compliance. My expertise spans both front-end and back-end development, enabling me to create scalable, efficient, and user-focused web applications.
  </li>
  <li>
    With proficiency in SQL and MongoDB, I excel in database design and management, developing optimized data structures and queries to handle large datasets and support seamless integration with front-end systems. My experience with NoSQL databases like MongoDB enhances my ability to work on modern, dynamic applications that require flexible data models.
  </li>
  <li>
    I am adept at implementing both Agile and Waterfall methodologies for project management, ensuring the delivery of high-quality solutions on time and within scope. My adaptability and problem-solving skills allow me to respond effectively to changing project requirements and client needs.
  </li>
  <li>
    Throughout my career, I have contributed to and managed web projects for esteemed clients, including American Express Insurance, Laurel Road, and Home Equity Bank (HEB). These projects required advanced technical expertise, meticulous attention to detail, and a focus on delivering exceptional user experiences tailored to client expectations.
  </li>
  <li>
    Specializing in user-centric web design, I emphasize intuitive interfaces, responsive layouts, and cross-browser compatibility to ensure a seamless user experience. My commitment to accessibility includes integrating modern design principles and adhering to WCAG standards, catering to diverse user needs.
  </li>
  <li>
            Delivered high-quality solutions as a freelancer, including the development of dynamic, responsive platforms for clients like Caring Connections and Tandon Gems.
            </li>
            <li>
    In addition to professional experience, I have pursued academic excellence through a Web Development Graduate Certificate, a Master’s in Advanced Computer Science, and a Bachelor’s in Computer Science and Engineering. These achievements, combined with hands-on industry experience, position me to address complex challenges and deliver innovative, efficient web development solutions.
  </li>
</ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
