import React from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hn37prc",
        "template_j8naooc",
        e.target,
        "p0ovmNiwFpwKyKCZb"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("Failed to send message, please try again.");
        }
      );

    e.target.reset();
  };

  return (
    <section className="py-16 bg-gray-900 text-white" id="contact">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-4">Contact Me</h2>
        <hr className="mb-8 border-gray-600" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-lg text-gray-300 mb-4">
              Let's get in touch. Send me a message:
            </p>
            <form onSubmit={sendEmail}>
              <div className="mb-4">
                <input
                  type="text"
                  name="from_name"
                  className="w-full p-4 bg-gray-800 text-white border border-gray-600 rounded focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="reply_to"
                  className="w-full p-4 bg-gray-800 text-white border border-gray-600 rounded focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="subject"
                  className="w-full p-4 bg-gray-800 text-white border border-gray-600 rounded focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="mb-4">
                <textarea
                  name="message"
                  className="w-full p-4 bg-gray-800 text-white border border-gray-600 rounded focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out"
                  placeholder="Your Message"
                  rows="5"
                  required
                ></textarea>
              </div>
              <input type="hidden" name="to_name" value="Abhishek" />
              <button
                type="submit"
                className="px-8 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600 transition duration-300 ease-in-out"
              >
                Send Message
              </button>
            </form>
          </div>
          <div>
            <p className="text-lg text-gray-300">
              Reach out via my social profiles or email:
            </p>
            <div className="mt-4">
              <a
                href="https://www.linkedin.com/in/abhishek-janga/"
                className="text-blue-500 hover:underline transition duration-300 ease-in-out"
              >
                LinkedIn
              </a>
              <br />
              <a
                href="https://github.com/Janga-Abhishek"
                className="text-blue-500 hover:underline transition duration-300 ease-in-out"
              >
                GitHub
              </a>
              <br />
              <span className="text-blue-500">janga.abhishek@gmail.com</span>
              <br />
              <span className="text-blue-500">+1 437 557 0534</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
