import React, { useEffect, useState } from "react";

const Menu = () => {
  const [activeTab, setActiveTab] = useState("Home");
  const [isMobile, setIsMobile] = useState(false);

  const menuItems = [
    { name: "Home", href: "#header" },
    { name: "About", href: "#about" },
    { name: "Stack", href: "#skills" },
    { name: "Education", href: "#education" },
    { name: "Experience", href: "#experience" },
    { name: "Projects", href: "#projects" },
    { name: "Contact", href: "#contact" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this width to match your design
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return;

    const handleScroll = () => {
      const sections = menuItems.map((item) =>
        document.querySelector(item.href)
      );

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const activeSection = menuItems.find(
                (item) => item.href === `#${entry.target.id}`
              );
              if (activeSection) {
                setActiveTab(activeSection.name);
              }
            }
          });
        },
        { threshold: 0.6 }
      );

      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });

      return () => {
        sections.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      };
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [menuItems, isMobile]);

  if (isMobile) {
    return null; // Return null to hide the menu on mobile screens
  }

  return (
    <div className="flex justify-center mt-4">
      <nav className="bg-white rounded-full shadow-lg p-2">
        <ul className="flex space-x-4">
          {menuItems.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                onClick={() => setActiveTab(item.name)}
                className={`px-4 py-2 rounded-full transition-all duration-300 ${
                  activeTab === item.name
                    ? "bg-black text-white shadow-xl"
                    : "text-gray-600 hover:bg-gray-100 hover:shadow-md"
                }`}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
