import React, { useEffect, useRef, useState } from "react";

const Education = () => {
  const [isVisible, setIsVisible] = useState(true);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (window.innerWidth > 768) {
          setIsVisible(entry.isIntersecting);
        } else {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      id="education"
      ref={sectionRef}
      className="relative overflow-hidden"
      style={{ paddingTop: "4rem", paddingBottom: "17rem" }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-gray-900 to-gray-200 opacity-20 z-10"></div>
      <div className="container mx-auto px-4 relative z-20">
        <h2 className="text-3xl font-bold mb-8 text-gray-900 text-center">
          Educational Qualifications
        </h2>
        <hr className="mb-8" />

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Ontario College Graduate Certificate */}
          <div
            className="p-6 bg-white rounded-lg shadow-md flex flex-col items-center"
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
          >
            <div style={{ backgroundColor: "white", padding: "10%" }}>
              <div
                className="w-full flex-shrink-0 flex justify-center mb-4"
                style={{
                  border: "4px solid beige",
                  borderRadius: "10%",
                  padding: "4%",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 1.2)",
                }}
              >
                <img
                  src="/images/Conestoga.png"
                  alt="Conestoga College Logo"
                  className="h-40 object-contain rounded-lg"
                />
              </div>
              <div className="flex-grow flex flex-col justify-between">
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-900 text-center">
                    Ontario College Graduate Certificate
                  </h3>
                  <p className="text-gray-700 text-center">
                    Web Development, Conestoga College, Kitchener, ON (05/2023 –
                    08/2024)
                  </p>
                </div>
                <p className="text-gray-700 text-center mt-4">
                  <b>CGPA:</b> 3.63
                </p>
              </div>
            </div>
          </div>

          {/* Master’s Degree */}
          <div
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
            className="p-6 bg-white rounded-lg shadow-md flex flex-col items-center"
          >
            <div style={{ backgroundColor: "white", padding: "10%" }}>
              <div
                className="w-full flex-shrink-0 flex justify-center mb-4"
                style={{
                  border: "4px solid #f40606",
                  borderRadius: "10%",
                  padding: "4%",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 1.2)",
                }}
              >
                <img
                  src="/images/UOL.jpg"
                  alt="University of Leicester Logo"
                  className="h-40 object-contain rounded-lg"
                />
              </div>
              <div className="flex-grow flex flex-col justify-between">
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-900 text-center">
                    Master’s Degree
                  </h3>
                  <p className="text-gray-700 text-center">
                    MSc in Advanced Computer Science with Industry, University
                    of Leicester, UK (01/2020 – 05/2022)
                  </p>
                </div>
                <p className="text-gray-700 text-center mt-4">
                  <b>Grade:</b> Merit
                </p>
              </div>
            </div>
          </div>

          {/* Bachelor’s Degree */}
          <div
            style={{
              background: "linear-gradient(45deg, black, white)",
              padding: "10px",
            }}
            className="p-6 bg-white rounded-lg shadow-md flex flex-col items-center"
          >
            <div style={{ backgroundColor: "white", padding: "10%" }}>
              <div
                className="w-full flex-shrink-0 flex justify-center mb-4"
                style={{
                  border: "4px solid #cdcd4d",
                  borderRadius: "10%",
                  padding: "4%",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 1.2)",
                }}
              >
                <img
                  src="/images/KITSW.png"
                  alt="KITSW Logo"
                  className="h-40 object-contain rounded-lg"
                />
              </div>
              <div className="flex-grow flex flex-col justify-between">
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-900 text-center">
                    Bachelor’s Degree
                  </h3>
                  <p className="text-gray-700 text-center">
                    Bachelor’s in Computer Science and Engineering, KITSW, India
                    (2015 - 2019)
                  </p>
                </div>
                <p className="text-gray-700 text-center mt-4">
                  <b>Grade:</b> A
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .bg-gradient-to-r {
            background-image: linear-gradient(to right, #ffffff, #000000);
          }
        `}
      </style>
    </section>
  );
};

export default Education;
